import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import parse from 'html-react-parser';

import SEO from '../components/SEO';
import Hero from '../components/shared/Hero';
import TextBlock from '../components/shared/TextBlock';
import Section from '../components/shared/Section';
import Form from '../components/shared/Form';
import Grid from '@material-ui/core/Grid';
import EnhancedGrid from '../components/shared/EnhancedGrid';

// component definition
const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      contentfulContactPage {
        page
        metaDescription
        hero {
          buttonLink
          buttonText
          headline
          supertext
          image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        textBlock {
          textBlockButtonLink
          textBlockSupertext
          textBlockHeadline
          textBlockButtonText
          textBlockBody {
            textBlockBody
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO
        title={data.contentfulContactPage.page}
        description={data.contentfulContactPage.metaDescription}
      />
      <Hero
        short
        bkgImage={data.contentfulContactPage.hero.image}
        headline={data.contentfulContactPage.hero.headline}
        buttonLink={data.contentfulContactPage.hero.buttonLink}
        buttonText={data.contentfulContactPage.hero.buttonText}
        supertext={data.contentfulContactPage.hero.supertext}
      />
      <div>
        <Section>
          <EnhancedGrid
            container
            justifyContent='space-evenly'
            align='flex-start'
            spacing={3}
          >
            <Grid item xs={12} sm={12} md={6}>
              <TextBlock
                superText={
                  data.contentfulContactPage.textBlock.textBlockSupertext
                }
                headline={
                  data.contentfulContactPage.textBlock.textBlockHeadline
                }
                body={parse(
                  data.contentfulContactPage.textBlock.textBlockBody
                    .textBlockBody
                )}
                buttonText={
                  data.contentfulContactPage.textBlock.textBlockButtonText
                }
                buttonLink={
                  data.contentfulContactPage.textBlock.textBlockButtonLink
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Form formName='contact-page' />
            </Grid>
          </EnhancedGrid>
        </Section>
      </div>
    </>
  );
};

export default ContactPage;
